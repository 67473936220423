import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { url } from '../../constant';

let permissions = {
  admin: null,
  sales: null,
  stock: null,
  whatsAppBulk: null,
  whatsAppChat: null,
  blockList: null,
  quickWA: null,
  attachment: null,
  device: null
}

export default function FormDialog(props) {

  const [whatsappNumber, setwhatsappNumber] = React.useState(props.selectedRow ? props.selectedRow.whatsappNumber : "")
  const [token, settoken] = React.useState(props.selectedRow ? props.selectedRow.token : "")
  const [email, setEmail] = React.useState(props.selectedRow ? props.selectedRow.email : "")
  const [password, setPassword] = React.useState("")
  const [fileName, setFileName] = React.useState(props.selectedRow ? props.selectedRow.fileName : "")
  const [schemeFileName, setSchemeFileName] = React.useState(props.selectedRow ? props.selectedRow.schemeFileName : "")
  const [stockFileName, setStockFileName] = React.useState(props.selectedRow ? props.selectedRow.stockFileName : "")
  const [activeStaus, setActiveStatus] = React.useState(props.selectedRow ? props.selectedRow.status : "")
  const [isDataLoading, setIsDataLoading] = React.useState(false)
  const [showResetPassword, setShowResetPassword] = React.useState(false)
  const [whatsappAccId, setWhatsappAccId] = React.useState(props.selectedRow ? props.selectedRow.whatsappAccNumber : "")
  const [third_party_api, setWAThirdPartyAPI] = React.useState(props.selectedRow ? props.selectedRow.third_party_api : "")
  const [messageLimit, setMessageLimit] = React.useState(props.selectedRow ? props.selectedRow.msg_limit : "")
  const [devicesAllowed, setDevicesAllowed] = React.useState(props.selectedRow ? props.selectedRow.allowed_app_user : "")
  const [mobileNumber, setMobileNumber] = React.useState(props.selectedRow ? props.selectedRow.mobile_number : "")
  const [otp_authentication, setOTPAuth] = React.useState(props.selectedRow ? props.selectedRow.otp_authentication : "")
  const [name, setName] = React.useState(props.selectedRow ? props.selectedRow.name : "")
  const [accessAllowed, setAccessAllowed] = React.useState(props.selectedRow ? {...permissions, ...props.selectedRow.access_allowed} : {})

// console.log("accessAllowed", accessAllowed);

  const createUser = () => {
    setIsDataLoading(true)

    if(!props.selectedRow){
      axios.post(`${url}/register/`, {
        "whatsapp_phone_no_id": whatsappNumber,
        "password": password,
        "email": email,
        "file_name": fileName,
        "scheme_file_name": schemeFileName,
        "stock_file_name": stockFileName,
        "whatsapp_token": token,
        "whatsapp_account_id": whatsappAccId,
        "allowed_app_user": devicesAllowed,
        "name": name,
        "msg_limit": messageLimit,
        "third_party_api": third_party_api,
        "mobile_number": mobileNumber,
        "otp_authentication": otp_authentication,
        "access_allowed": accessAllowed
      }, { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } }).then((res) => {
        setIsDataLoading(false)
        props.handleCancel()
      }).catch(e => { console.log(e); setIsDataLoading(false) })
    } else {
      let payload;
      if(showResetPassword){
        payload = {"password":password,"id": props.selectedRow.id}
      } else {
        payload = {
          "id": props.selectedRow.id, 
          "whatsapp_phone_no_id": whatsappNumber, 
          "email": email, 
          "file_name": fileName, 
          "scheme_file_name": schemeFileName,
          "stock_file_name": stockFileName,
          "whatsapp_token": token, 
          "is_active": activeStaus, 
          "whatsapp_account_id": whatsappAccId, 
          "allowed_app_user": devicesAllowed,
          "name": name,
          "msg_limit": messageLimit,
          "third_party_api": third_party_api,
          "mobile_number": mobileNumber,
          "otp_authentication": otp_authentication,
          "access_allowed": accessAllowed
        }
      }
      axios.post(`${url}/simistocks/update_user`, payload, { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } }).then((res) => {
        setIsDataLoading(false)
        props.handleCancel()
      }).catch(e => { console.log(e); setIsDataLoading(false) })
    }
  }

  const handleFeatureAccessChange = e => {
    if(e.target.checked) {
      setAccessAllowed({...accessAllowed, [e.target.name]: true})
    } else {
      setAccessAllowed({...accessAllowed, [e.target.name]: null})
    }
    
}

  return (
    <div>
      <Dialog open={true} style={{minWidth: "400px", minHeight: "200px"}}>
        <DialogTitle>{showResetPassword ? "Reset Password" : props.selectedRow ? "Update User" : "Create User"}</DialogTitle>
        <DialogContent>

          {showResetPassword ?
            <TextField
              
              label="Password"
              type="text"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          : <>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
            <TextField
                
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {!props.selectedRow ? <TextField
                
                label="Password"
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              /> : <FormControlLabel control={<Checkbox onClick={() => setActiveStatus(!activeStaus)} checked={activeStaus} />} label="Active Status" />}
              <TextField
                
                label="Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                
                label="File Name"
                type="text"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
              <TextField
                
                label="Scheme File Name"
                type="text"
                value={schemeFileName}
                onChange={(e) => setSchemeFileName(e.target.value)}
              />
              <TextField
                
                label="Stock File Name"
                type="text"
                value={stockFileName}
                onChange={(e) => setStockFileName(e.target.value)}
              />
              <TextField
                
                label="Whatsapp Phone No Id"
                type="text"
                value={whatsappNumber}
                onChange={(e) => setwhatsappNumber(e.target.value)}
              />
              <TextField
                
                label="Whatsapp Token"
                type="text"
                value={token}
                onChange={(e) => settoken(e.target.value)}
              />
              <TextField
                
                label="Whatsapp Account ID"
                type="text"
                value={whatsappAccId}
                onChange={(e) => setWhatsappAccId(e.target.value)}
              />
              <TextField
                
                label="WA 3rd party API"
                type="text"
                value={third_party_api}
                onChange={(e) => setWAThirdPartyAPI(e.target.value)}
              />
              <TextField
                
                label="Message Limit"
                type="number"
                value={messageLimit}
                onChange={(e) => e.target.value >= 0 ? setMessageLimit(e.target.value) : null}
              />
              <TextField
                
                label="No. of Devices Allowed"
                type="number"
                value={devicesAllowed}
                onChange={(e) => e.target.value >= 0 ? setDevicesAllowed(e.target.value) : null}
              />
              <TextField
                label="Mobile Number"
                type="number"
                value={mobileNumber}
                onChange={(e) => e.target.value >= 0 ? setMobileNumber(e.target.value) : null}
              />
              <FormControlLabel 
                sx={{m: 0}} 
                control={<Checkbox name={"otp_authentication"} checked={otp_authentication} onClick={e => setOTPAuth(!otp_authentication)} />} 
                label="OTP Auth" 
              />
              <FormControlLabel sx={{m: 0}} control={<Checkbox name={"admin"} checked={accessAllowed?.admin} onClick={handleFeatureAccessChange} />} label="Admin Previlages" />
              <FormControlLabel sx={{m: 0}} control={<Checkbox name={"sales"} checked={accessAllowed?.sales} onClick={handleFeatureAccessChange} />} label="Sales" />
              <FormControlLabel sx={{m: 0}} control={<Checkbox name={"stock"} checked={accessAllowed?.stock} onClick={handleFeatureAccessChange} />} label="Stock" />
              <FormControlLabel sx={{m: 0}} control={<Checkbox name={"whatsAppBulk"} checked={accessAllowed?.whatsAppBulk} onClick={handleFeatureAccessChange} />} label="WhatsApp Bulk" />
              <FormControlLabel sx={{m: 0}} control={<Checkbox name={"whatsAppChat"} checked={accessAllowed?.whatsAppChat} onClick={handleFeatureAccessChange} />} label="WhatsApp Chat" />
              <FormControlLabel sx={{m: 0}} control={<Checkbox name={"blockList"} checked={accessAllowed?.blockList} onClick={handleFeatureAccessChange} />} label="Block List" />
              <FormControlLabel sx={{m: 0}} control={<Checkbox name={"quickWA"} checked={accessAllowed?.quickWA} onClick={handleFeatureAccessChange} />} label="Quick WA" />
              <FormControlLabel sx={{m: 0}} control={<Checkbox name={"attachment"} checked={accessAllowed?.attachment} onClick={handleFeatureAccessChange} />} label="Attachment" />
              <FormControlLabel sx={{m: 0}} control={<Checkbox name={"device"} checked={accessAllowed?.device} onClick={handleFeatureAccessChange} />} label="Device" />
            </div>
            {<p style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => setShowResetPassword(true)}>Reset password</p>}
          </>}
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
          <Button variant="outlined" style={{border: "1px solid rgba(25, 118, 210, 0.5)", color: "#1976d2"}} onClick={props.handleCancel}>Cancel</Button>
          <Button variant='contained' style={{backgroundColor: "#1976d2"}} onClick={createUser} >{showResetPassword || props.selectedRow ? "Update" : "Create"}</Button>
          {(showResetPassword || props.selectedRow) && <Button variant='contained' color={"error"} onClick={props.handleDelete} >Delete</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
