import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./Components/UIComponents/MyLogin";
import Forbidden from "./Components/UIComponents/Forbidden";
import MyLayout from "./Components/UIComponents/MyLayout";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Components/Layout";
import "./App.css"
import { useEffect } from "react";
import axios from "axios";
import { url } from "./constant";
import { forbidden } from "./slices/auth";
import { useDispatch } from "react-redux";

function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const ping = (fcmToken) => {
    axios.post(`${url}/simistocks/ping`,
      {fcmToken},
      {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`}})
      .then(res => {
        if(!res.data.is_approved) {
          if(!location.pathname.includes("forbidden")){
            dispatch(forbidden());
            localStorage.setItem("forbidden", true);
          }
        } else {
          if(localStorage.getItem("forbidden") || location.pathname.includes("forbidden")){
            navigate("/");
            localStorage.removeItem("forbidden");
          }
        }
      })
      .catch(error => console.log(error.message))
  }

  const setupPing = async () => {
    // onTokenRefresh
    if(window && window.FirebasePlugin){
      await window.FirebasePlugin.getToken(token => {
        ping(token)
        setInterval(() => {
          ping(token)
        }, 3 * 60 * 1000)
      }, error => console.log("FCM Token Error", error))
    }
  }

  useEffect(() => {
    document.addEventListener("backbutton", onBackButton, false);
    setupPing()
  })

  function onBackButton() {
    // Handle the back button press here
    window.navigator.notification?.confirm(
        "Do you want to exit the app?",
        onConfirmExit,
        "Exit App",
        ["Yes", "No"]
    );
  }

  function onConfirmExit(buttonIndex) {
    if (buttonIndex === 1) {
        window.navigator.app.exitApp(); // Exit the app
    }
  }
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/login" element={<Login/>}/>
        <Route path="/forbidden" element={<Forbidden/>}/>
      </Routes>
    </div>
  );
}

export default App;
