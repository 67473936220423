import { Avatar, Box, ButtonBase, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../http-common';
import moment from 'moment';
import SelectChat from './SelectChat';
import { Done, DoneAll, EmojiEmotionsOutlined, AttachFile, Close } from '@mui/icons-material';
import Picker from 'emoji-picker-react';

let intervalRef = null;

function WhatsappChat() {
    const [messages, setMessages] = useState([]);
    const [chats, setChats] = useState([]);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [message, setMessage] = useState("");
    const [filteredChats, setFilteredChats] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFilePreview, setSelectedFilePreview] = useState(null);
    const messagesEndRef = useRef(null);
    const emojiPickerRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    };

    const onEmojiClick = (emojiObject, event) => {
        setMessage(message + emojiObject.emoji);
    };

    useEffect(() => {
        axiosInstance.get("/simistocks/list_user_chat", { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } })
            .then(res => {
                setChats(res.data);
                setFilteredChats(res.data);
            });
        return () => clearInterval(intervalRef);
    }, []);

    useEffect(() => {
        if (!selectedUser) return;
        clearInterval(intervalRef);

        axiosInstance.get(`/simistocks/list_user_chat?phone_no=${selectedUser}`, { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } })
            .then(res => {
                setMessages(res.data);
                setTimeout(() => scrollToBottom(),);
            });

        intervalRef = setInterval(() => {
            axiosInstance.get(`/simistocks/list_user_chat?phone_no=${selectedUser}`, { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } })
                .then(res => {
                    setMessages(res.data);
                });
        }, 10 * 1000);
    }, [selectedUser]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [emojiPickerRef]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Check if the file size exceeds 5MB
            if (file.size > 5 * 1024 * 1024) { // 5MB limit
                alert("File size exceeds 5MB limit. Please choose a smaller file.");
                return;
            }

            // Check if the file is a video and only allow MP4 format
            if (file.type.startsWith("video/") && file.type !== "video/mp4") {
                alert("Only MP4 video files are allowed. Please choose a valid MP4 file.");
                return;
            }

            // Set the file preview for images, videos, and documents
            let filePreview = null;
            if (file.type.startsWith("image/")) {
                filePreview = URL.createObjectURL(file);
            } else if (file.type === "video/mp4") {
                filePreview = URL.createObjectURL(file);
            } else {
                filePreview = "document"; // Indicate it's a document
            }

            setSelectedFilePreview(filePreview);
            setSelectedFile(file);
        }
    };


    const handleSend = async (e) => {
        e.preventDefault();

        if (!message.trim() && !selectedFile) return;

        const formData = new FormData();
        formData.append("phone_no", selectedUser);
        formData.append("message", message);
        if (selectedFile) {
            const fileType = selectedFile.type.split('/')[0];
            if (fileType === 'image') {
                formData.append("image", selectedFile);
            } else if (fileType === 'video') {
                formData.append("video", selectedFile);
            } else {
                formData.append("document", selectedFile);
            }
        }

        try {
            const res = await axiosInstance.post("/simistocks/send_message", formData, {
                headers: {
                    "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`,
                    "Content-Type": "multipart/form-data"
                }
            });

            const newMessage = {
                phone_no: selectedUser,
                message: message,
                file: selectedFile ? URL.createObjectURL(selectedFile) : null,
                status: "done",
                message_type: "response",
                datetime: moment().utc()
            };

            setMessages([...messages, newMessage]);
            setMessage("");
            setSelectedFile(null);
            setSelectedFilePreview(null);
            setTimeout(() => scrollToBottom(),);
        } catch (error) {
            console.error(error.response);
            setMessage("");
            setSelectedFile(null);
            setSelectedFilePreview(null);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.shiftKey) {
            event.preventDefault();
            setMessage(prevText => prevText + '\n');
        } else if (event.key === 'Enter' && !event.shiftKey) {
            handleSend(event);
        }
    };

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        if (query.trim()) {
            const filtered = chats.filter(chat => chat.phone_no.includes(query));
            setFilteredChats(filtered);
        } else {
            setFilteredChats(chats);
        }
    };

    return (
        <Grid container>
            <Grid item container xs={12} md={3} sx={{ borderRight: "2px solid #f3f3f3", height: "100%", overflowY: "auto" }} maxHeight={"calc(100vh - 70px)"}>
                <TextField
                    fullWidth
                    placeholder="Search by phone number"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{ marginBottom: 2, position: "sticky", top: 0, backgroundColor: "#fff", zIndex: 99 }}
                />
                {filteredChats.map(e =>
                    <ButtonBase component="div" sx={{ width: "100%" }} onClick={r => setSelectedUser(e.phone_no)}>
                        <Grid container item xs={12} sx={{ background: selectedUser === e.phone_no ? "#f0f0f0" : "" }} className={"wa-user"} alignItems={"center"} gap={"1rem"}>
                            <Grid item xs={2} p={2}>
                                <Avatar>U</Avatar>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography textAlign={"left"} width={"100%"} >{e.phone_no}</Typography>
                                <Typography textAlign={"left"} variant={"subtitle2"} width={"100%"} >{e.message.message?.replace("\\n", "").slice(0, 35)}...</Typography>
                            </Grid>
                        </Grid>
                    </ButtonBase>
                )}
            </Grid>
            {
                !messages.length ? <SelectChat />
                    :
                    <Grid item xs={12} md={9} maxHeight={"calc(90vh"} container sx={{ position: "relative" }}>
                        <Grid item container xs={12} justifyContent={"center"} maxHeight={"70px"} alignItems={"center"} boxShadow={"0px 0px 2px #000"}>
                            <h3 style={{ margin: 0 }}>Chat with {selectedUser}</h3>
                        </Grid>
                        <Grid item xs={12} container maxHeight={"calc(100vh - 220px)"} sx={{ overflowY: "auto" }}>
                            <Grid item xs={12}>
                                {messages?.map((e, index) => (
                                    <Grid key={index} container item xs={12} className='wa-message' justifyContent={e.message_type === "received" ? "start" : "end"}>
                                        <Grid item maxWidth={"60% !important"}>
                                            <Typography
                                                sx={e.message_type !== "received" ? { float: "right", position: "relative", pr: "40px" } : { float: "left" }}
                                                align={e.message_type === "received" ? "left" : "left"}
                                                className={e.message_type === "received" ? "left-msg msg" : "right-msg msg"}
                                            >
                                                {(e.data?.image || e.data?.video || e.data?.document) && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {e.data?.image && (
                                                            <img
                                                                src={e.data?.image}
                                                                alt="attachment"
                                                                style={{ width: 'calc(100% + 30px)', marginBottom: '10px' }}
                                                            />
                                                        )}
                                                        {e.data?.video && (
                                                            <video
                                                                controls
                                                                src={e.data?.video}
                                                                style={{ width: 'calc(100% + 30px)', marginBottom: '10px' }}
                                                            />
                                                        )}
                                                        {e.data?.document && (
                                                            <a
                                                                href={e.data?.document}
                                                                download
                                                                style={{ width: 'calc(100% + 30px)', marginBottom: '10px' }}
                                                            >
                                                                Download Document
                                                            </a>
                                                        )}
                                                        {/* <a
                                                        href={e.file}
                                                        download
                                                        style={{ display: 'block', textDecoration: 'underline', color: 'blue' }}
                                                    >
                                                        Download Attachment
                                                    </a> */}
                                                    </div>
                                                )}

                                                <span dangerouslySetInnerHTML={{ __html: e.data?.message?.replaceAll("\\n", "<br/>") }}></span>
                                                {e.message_type !== "received" && (
                                                    <span style={{ position: "absolute !important", bottom: 0, right: 10 }}>
                                                        {e.status === "delivered" ?
                                                            <DoneAll fontSize={"small"} />
                                                            : e.status === "read" ?
                                                                <DoneAll color={"primary"} fontSize={"small"} />
                                                                : <Done fontSize={"small"} />
                                                        }
                                                    </span>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                align={e.message_type === "received" ? "left" : "right"}
                                                className={"time-txt"}
                                            >
                                                {moment(e.datetime).format("DD MMM YY | hh:mm")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}

                                <div ref={messagesEndRef} />
                            </Grid>
                        </Grid>
                        <Grid ref={emojiPickerRef} component={"form"} item xs={12} sx={{ position: "absolute !important", bottom: 0, width: "72%" }}>
                            {showEmojiPicker && (
                                <Picker onEmojiClick={onEmojiClick} width={"100%"} />
                            )}
                            <IconButton sx={{ position: "absolute !important", bottom: "3px", zIndex: 99 }} onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                {showEmojiPicker ? <Close fontSize={"large"} /> : <EmojiEmotionsOutlined fontSize={"large"} />}
                            </IconButton>
                            <IconButton sx={{ position: "absolute !important", bottom: "3px", left: "50px", zIndex: 99 }} component={"label"}>
                                <AttachFile fontSize={"large"} />
                                <input type="file" hidden onChange={handleFileChange} />
                            </IconButton>
                            <TextField InputProps={{ sx: { pl: "100px" } }} multiline type={"text"} fullWidth placeholder={"Type message here"} onKeyDown={handleKeyDown} onChange={e => setMessage(e.target.value)} value={message} />
                        </Grid>
                        {selectedFilePreview && (
                            <Grid container item alignItems={"center"} justifyContent={"center"} mt={2} sx={{ position: "absolute !important", bottom: "60px", maxWidth: "30%", width: "max-content", border: "1px solid #ccc", borderRadius: "12px", overflow: "hidden", backgroundColor: "#fff" }}>
                                <Grid item xs={12} textAlign={"center"}>
                                    <Typography variant="body2" p={"10px"}>Attachment Preview</Typography>
                                    {selectedFilePreview === "document" ? (
                                        <Box minHeight={"100px"} height={"max-content"} backgroundColor={"#bbb"} pt={3}>
                                            <Typography variant="body2">
                                                {selectedFile.name} - <br/> Document ready to send.
                                            </Typography>
                                        </Box>
                                    ) : selectedFile.type.startsWith("image/") ? (
                                        <img src={selectedFilePreview} alt="preview" style={{ maxWidth: "100%", marginBottom: "10px", maxHeight: "200px"}} />
                                    ) : selectedFile.type === "video/mp4" ? (
                                        <video controls style={{ maxWidth: "100%", marginBottom: "10px", maxHeight: "200px"}}>
                                            <source src={selectedFilePreview} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : null}
                                </Grid>
                                <Grid item xs={2} sx={{ position: "absolute !important", top: "5px", right: "5px", maxWidth: "30%" }}>
                                    <IconButton onClick={() => { setSelectedFile(null); setSelectedFilePreview(null); }} sx={{backgroundColor: "#ccc !important", p: "2px !important"}}>
                                        <Close sx={{ color: "#fff" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
            }
        </Grid>
    )
}

export default WhatsappChat;
