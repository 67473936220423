
// exchane_wp_msg
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
// import columns from "../columnFormat"
// import { downloadCSV } from 'react-admin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import jsonExport from 'jsonexport/dist';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import noRecordsFound from "./no_records_found.png"
import { Bars } from 'react-loader-spinner';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatsAppPopup from '../User/WhatsApp';
import SROffersGrid from '../User/SROffersGrid';
import axios from 'axios';
import WhatsAppMessageResultsDialoge from '../User/WhatsAppMessageResultsDialoge';
import FilterListIcon from '@mui/icons-material/FilterList';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';
import AlertDialog from './ConfirmationDialog';
import { useState } from 'react';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { url } from '../../constant';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { List, ListItem } from '@mui/material';
import SelectAutoComplete from "./SelectAutoComplete"

const columns = [
  { id: 'a1', label: 'File name ', minWidth: 170, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'b1', label: 'Stock item', minWidth: 200, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'c1', label: 'Stock group', minWidth: 200, align: 'left', format: (value) => <p style={{width: "9rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#0b88fe"}}>{value}</p>, },
  { id: 'd1', label: 'Shop', minWidth: 130, align: 'left', format: (value) => <p style={{width: "10rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'e1', label: 'Qty', minWidth: 170, align: 'center', format: (value) => <p style={{width: "2rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'f1', label: 'MOP', minWidth: 170, align: 'left', format: (value) => <p style={{width: "5rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'schemes', label: 'Offers', minWidth: 170, align: 'left', format: (value) => <p style={{width: "5rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const exporter = (selectedStocks, stocks) => {
  // let head = columns.map(headCell => {return { : }})
  // console.log("selectedStocks", selectedStocks, stocks);
  let stockForExport = []
  const stocksForExport = selectedStocks.map(selectedStock => {
      for(let i in stocks){
        if(`${stocks[i].K4}_${stocks[i].K8}` === selectedStock){
          // console.log("stocks[i]", stocks[i]);
          let {K1, K2, K3, K4, K5, K6, K7, K8, K9, K10, K11, K12, K13, K14, K15, K16, K17, K18, K19} = stocks[i]
          stockForExport.push({"Date": K1, "VCH Type": K2, "Invoice Number": K3, "Customer Name": K4, 
          "Contact": K5, "Stock Item": K6, "Stock Group": K7, "IMEI Number": K8, "Godown": K9, "Quantity": K10, "Total": K11, 
          "Finance Name": K12, "Brand": K13, "Salesman": K14, "Category": K15, "Stock Alias": K16, "DOB": K18, "PDF File Name": K19})
        }
      }
      return stockForExport
  });

  // jsonExport(stockForExport, {}, (err, csv) => {
  //     downloadCSV(csv, 'stocks');
  // });
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const [datePopupOpen, setDatePopupOpen] = React.useState(false)
  const [startDate, setStartDate] = React.useState(props.datesRange[0].startDate)
  const [endDate, setEndDate] = React.useState(props.datesRange[0].endDate)
  const [searchQuery, setSearchQuery] = React.useState("")
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [showSearchByGroup, setShowSearchByGroup] = React.useState(true)
  const [showSearchByItems, setShowSearchByItems] = React.useState(false)
  const [stockItemList, setStockItemList] = React.useState([])
  const [stockGroupList, setStockGroupList] = React.useState([])
  const [filters, setFilters] = React.useState(props.filters)
  const [datesRange, setDatesRange] = React.useState(
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  );

  const deleteHandler = () => {
    axios.post(`${url}/simistocks/delete_data`,
      {},
      // {"phone_numbers":numberLs}, 
      {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`}})
      .then(res => {
       setAlertOpen(false)
       props.setRes([])
      })
      .catch(error => console.log(error.message))
  }

  React.useEffect(() => {
    if(props.datesRange[0].startDate){
      setStartDate(moment(props.datesRange[0].startDate))
    }
    if(props.datesRange[0].endDate){
      setEndDate(moment(props.datesRange[0].endDate))
    }
  }, [props.datesRange])

  React.useEffect(() => {
    let allStockItem = props.apiData?.map((stock) => stock.b1)
    let set = new Set(allStockItem)
    let distinctStockItems = [...set].filter(ob => ob != null)
    setStockItemList(distinctStockItems)
  },[props.apiData])

  React.useEffect(() => {
      let allStockGroup = props.apiData?.map((stock) => stock.c1)
      let set = new Set(allStockGroup)
      let distinctStockGroup = [...set].filter(ob => ob != null)
      setStockGroupList(distinctStockGroup)
  },[props.apiData])

  const handleFilterSelect = (e) => {
    if(e.target.value === null){
      let filter = filters
      delete filter[e.target.name]
      setFilters(filter)
    }
    else setFilters({...filters, [e.target.name]: e.target.value})

    props.setFilters({...props.filters, ...{...filters, [e.target.name]: e.target.value}, searchQuery: searchQuery})
  }

  let direction = window.screen.width < 480 ? "column" : ""
  let align = window.screen.width < 480 ? "start" : ""
  return (
    <>
      <Toolbar
        sx={{
          flexDirection: "row",
          alignItems: align,
          flexWrap: "wrap",
          justifyContent: "space-between",
          p: 0,
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >

        {numSelected === 0 && <form style={{display: "flex", width: "100%", justifyContent: "center"}} onSubmit={(e) => {e.preventDefault();props.setFilters({...props.filters, ...filters, searchQuery: searchQuery})}}>
          <List style={{width: "100%"}}>
            <ListItem sx={{display: showSearchByGroup || showSearchByItems ? "none" : ""}}>
              <FormControl sx={{ m: 0, width: '100%' }} variant="filled">
                <InputLabel htmlFor="filled-adornment-password">Search</InputLabel>
                <FilledInput
                style={{border: "1px solid rgb(18 26 78 / 31%)", borderRadius: "5px", cursor: "pointer", background: "#fff", color: "rgb(18, 26, 78)"}}
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton type='submit' style={{background: "#000 !important"}}><SearchIcon /></IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </ListItem>
            <ListItem sx={{display: !showSearchByItems ? "none" : ""}}>
              <FormControl fullWidth>
                <SelectAutoComplete filters={filters} data={stockItemList} value={filters.stockItem} label="Search by Stock Items" handleFilterSelect={handleFilterSelect} name="stockItem" />
              </FormControl>
            </ListItem>
            <ListItem sx={{display: !showSearchByGroup ? "none" : ""}}>
              <FormControl fullWidth>
                <SelectAutoComplete filters={filters} data={stockGroupList} value={filters.stockGroup} label="Search by Stock Groups" handleFilterSelect={handleFilterSelect} name="stockGroup" />
              </FormControl>
            </ListItem>
          </List>
        </form>}
        {numSelected > 0 && (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        )}

        {numSelected === 0 && <div style={{display: "flex", flexWrap: "nowrap", gap: "1rem", paddingLeft: "10px", justifyContent: "space-between", width: "100%"}}>
          {/* <FormControlLabel sx={{marginRight: 0}} control={<Checkbox 
            onClick={(event) => props.setFilters({...props.filters, showOffersItems: !props.filters.showOffersItems})}
            color="primary"
            checked={props.filters.showOffersItems}/>} label="Only offers" /> */}
          
          <FormControlLabel control={<Checkbox 
            onClick={(event) => {setShowSearchByGroup(event.target.checked);setShowSearchByItems(false)}}
            color="primary"
            checked={showSearchByGroup}/>} label="Groups" />
          
          <FormControlLabel control={<Checkbox 
            onClick={(event) => {setShowSearchByItems(event.target.checked);setShowSearchByGroup(false)}}
            color="primary"
            checked={showSearchByItems}/>} label="Items" />

          <Tooltip title="Filters">
            <IconButton onClick={() => props.setFiltersOpen(true)}>
              <FilterListIcon style={{color: "#121a4e"}} />
            </IconButton>
          </Tooltip>
          {/* {(props.showFilters || datesRange.startDate || datesRange.endDate ) && <Button onClick={() => {props.clearFilter({});setSearchQuery("");setDatesRange({startDate: "", endDate: ""});}} style={{border: "1px solid #121a4e", borderRadius: "5px", cursor: "pointer", padding: "0.4rem 1rem", background: "#fff", color: "rgb(18, 26, 78)", fontWeight: "bold", textDecoration: "line-through"}}>
            Filters
          </Button>} */}

          {(props.showFilters || datesRange.startDate || datesRange.endDate ) && 
            <Button 
              onClick={() => {
                props.clearFilter({});
                setSearchQuery("");
                setDatesRange({startDate: moment().format("YYYY-MM-DD"),endDate: moment().format("YYYY-MM-DD"),key: 'selection'});
                props.setDatesRange([{startDate: moment().format("YYYY-MM-DD"),endDate: moment().format("YYYY-MM-DD"),key: 'selection'}])
                setFilters({});
              }} 
              style={{
                border: "1px solid #121a4e", 
                borderRadius: "5px", 
                cursor: "pointer", 
                padding: "0px 10px", 
                background: "#fff", 
                color: "rgb(18, 26, 78)", 
                fontWeight: "bold", 
                // marginTop: "-7px", 
                // marginLeft: "10px", 
                // height: "30px",
                textDecoration: "line-through",
                minWidth: "max-content"
              }}>
              Clear Filters
            </Button>}
          </div>
        }
        {props.selected.length > 0 && <Tooltip title="Export">
          <IconButton onClick={() => exporter(props.selected, props.res)}>
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>}
      </Toolbar>
      <AlertDialog deleteHandler={deleteHandler} handleClose={() => setAlertOpen(false)} open={alertOpen} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


let numberList = []

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showWhatsAppPopup, setShowWhatsAppPopup] = React.useState(false)
  const [hideDropdown, setHideDropdown] = React.useState(false)
  // const [numberList, setNumberList] = React.useState([])
  const [whatsAppMessageResults, setWhatsAppMessageResults] = React.useState(null)
  const [total, setTotal] = React.useState(0)
  const [quantity, setQuantity] = React.useState(0)
  const [exchangeList, setExchangeList] = useState([])
  const [openGridPopup, setOpenGridPopup] = useState(false)
  const [offerTitle, setOfferTitle] = useState("")
  const [popupData, setPopupData] = useState([])
  const [showItemsWithScheme, setShowItemsWithScheme] = React.useState(false)

  const [isSendInProgress, setSendInProgress] = React.useState(false)
  const rows = groupObjectsByKey(props.res, "b1")

  React.useEffect(() => {
    let total = 0;
    let quantity = 0;
    
    if(props.res){
      for(let i=0; i<props.res.length; i++){
        if(!isNaN(props.res[i].K11)) total = total + props.res[i].K11
        if(!isNaN(props.res[i].K10)) quantity = quantity + props.res[i].K10
      }

      setTotal(total)
      setQuantity(quantity)
    }
  },[props.res])

  React.useEffect(() => {
    if(selected.length === 0){
      setHideDropdown(false)
      setExchangeList([])
    }
  },[selected])

  React.useEffect(() => {
    setRowsPerPage(10)
    setPage(0)
  }, [props.filters])
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openGrid = (schemes, title) => {
    if(!schemes.length) return

    setOpenGridPopup(true)
    setPopupData(schemes)
    setOfferTitle(title)
  }

  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const isSelected = (name) => exchangeList.some(row => name === `${row.K4}_${row.K8}_${row.K6}`);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const ROWS = Object.keys(rows)
  const ROWS1 = stableSort(Object.values(rows), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <Box >
      <Paper sx={{ width: '100%', mb: 2, maxHeight: "max-content", height: "max-content", }}>
        <EnhancedTableToolbar 
          setRes={props.setRes} 
          filters={props.filters} 
          setFilters={props.setFilters} 
          showFilters={Object.keys(props.filters).length > 0} 
          clearFilter={props.clearFilter} 
          setFiltersOpen={props.setFiltersOpen} 
          setShowWhatsAppPopup={setShowWhatsAppPopup} 
          setHideDropdown={setHideDropdown} 
          rows={rows} 
          selected={selected} 
          res={props.res} 
          numSelected={exchangeList.length} 
          setDatesRange={props.setDatesRange} 
          exchangeList={exchangeList} 
          datesRange={props.datesRange}
          apiData={props.res}
        />
        <TableContainer sx={{maxHeight: "66vh", height: "70vh", minHeight: props.isDataLoading ? 400 : "max-content"}}>
          <Table
            aria-labelledby="tableTitle"
            size={'small'}
            stickyHeader
            style={{maxHeight: "70vh", height: "max-content"}}
          >
           {ROWS.length > 0 ? <>
            <TableHead>
                <TableRow>
                  <TableCell>Shop</TableCell>
                  <TableCell align='center'>Quantity</TableCell>
                  <TableCell align='center'>MOP</TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(ROWS, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return <Row openGrid={openGrid} key={row} rowKey={row} title={ROWS[index]} row={ROWS1[index]} />
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody></> : 
            props.isDataLoading ? 
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Bars wrapperStyle={{justifyContent: "center", height: 400, alignItems: 'center'}} color="rgb(18, 26, 78)" height={80} width={80} /> 
                    </TableCell>
                </TableRow>
              </TableBody>
            :
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9}>
                    <div style={{display: "grid", justifyItems: "center"}}>
                      <img style={{width: "100%", maxWidth: "700px"}} src={noRecordsFound} alt='img' />
                      {false ? <p>No record found with selected date range, please try changing range</p>
                      : <p>No record found for this user</p>}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
        {ROWS.length > 0 && <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={ROWS.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '.MuiTablePagination-toolbar': {
              padding: "0px 10px"
            },
            '.MuiTablePagination-actions': {
              m: "0px 10px"
            },
            '.MuiInputBase-root': {
              m: "0px 10px"
            },
          }}
        />}
      </Paper>
      {
        openGridPopup && <SROffersGrid title={offerTitle} data={popupData} close={() => setOpenGridPopup(false)} />
      }
    </Box>
  );
}


// 
// {datePopupOpen ?
//   <div className={classes.datePicker}>
//     <DateRange
//       editableDateInputs={true}
//       onChange={item => setDatesRange([item.selection])}
//       moveRangeOnFirstSelection={false}
//       ranges={datesRange}
//     />
//     <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
//       <button className={classes.applyButton} style={{cursor: "pointer"}} onClick={() => {props.setDatesRange(datesRange);toggleDatePicker()}}>Apply</button>
//       <button className={classes.applyButton} style={{background: "#ff003e", cursor: "pointer"}} onClick={toggleDatePicker}>Cancel</button>
//     </div>
//   </div>
//  : <>Date: <Button type="text" className={classes.applyButton} onClick={toggleDatePicker}>{`From: ${startDate ? startDate.format("DD-MM-YYYY") : "Select"} - To: ${endDate ? endDate.format("DD-MM-YYYY") : "Select"}`}</Button></>}
// 


function groupObjectsByKey(objects, key) {
  const groupedObjects = {};

  objects?.forEach(obj => {
      if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (!groupedObjects[value]) {
              groupedObjects[value] = [];
          }
          groupedObjects[value].push(obj);
      }
  });

  return groupedObjects;
}


function Row(props) {
  const { row, title, rowKey, openGrid } = props;
  const [open, setOpen] = React.useState(false);

// console.log("row", row[0].schemes);
// Row BG Color #121b4e0d
  return (
    <React.Fragment key={rowKey}>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: "#d4f4fb45" }} onClick={() => setOpen(!open)}>
        <TableCell sx={{paddingLeft: 2}} colSpan={3} width={350} component="th" scope="row">
            {title}
        </TableCell>
      </TableRow>
      {row?.map((r) => (
        <TableRow key={`${r.c1}-${r.b1}-${r.d1}`} >
          <TableCell >
            {r.d1}
          </TableCell>
          <TableCell align="center">{r.e1}</TableCell>
          <TableCell align="center">{r.f1} {row[0].schemes.length ? <Button onClick={() => openGrid(row[0].schemes, title)} variant={"contained"} style={{padding: "0px 4px", background: "#178a2f", fontSize: "0.7rem", minWidth: "max-content"}}>offer</Button> : null}</TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}
