import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import OfferCard from "./OfferCard";

function SROffersGrid(props) {
  return (
    <Dialog open={true} sx={{overflowX: "hidden"}} PaperProps={{style: {width: "94vw", maxWidth: "360px", margin: 0}}} onClose={props.close}>
        <DialogTitle style={{display: "flex", justifyContent: "space-between", alignItems: "start", gap: "1rem", padding: "15px 16px"}}>
          <Typography fontWeight={"bold"} variant='body1'>{props.title}</Typography>
          <IconButton style={{padding: 0}} onClick={props.close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent 
          sx={{
              minHeight: "max-content", 
              maxHeight: "75vh", 
              overflowX: "hidden", 
              display: "flex",
              flexDirection: "column", 
              gap: "0rem",
              margin: "15px",
              border: "1px solid #898989",
              borderRadius: "5px",
              padding: "0px!important"
            }}>
          {props.data.map(c => <OfferCard data={c} />)}
        </DialogContent>
      </Dialog>
  )
}

export default SROffersGrid